import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp"
import { LoginCard } from '@/components/layout/login-card';
import { Button, FormLabel, StandaloneLink } from '@/components/wm';
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSlot,
  } from "@/components/ui/input-otp"
import { apiRequest } from '@/utils/request';
import * as React from 'react';
import { useState, useEffect } from 'react';

export function LoginOtp({ email, password, onAlert, backToLogin }) {
    const
        [autoTrigger, setAutoTrigger] = useState(true),
        [code, setCode] = useState(''),
        [formFilled, setFormFilled] = useState(false),
        [loadingSubmit, setLoadingSubmit] = useState(false),
        [loadingResend, setLoadingResend] = useState(false);

    useEffect(() => {
        const formFilled = code.length === 6;

        setFormFilled(formFilled);
    }, [code]);

    const onOtpChange = (value) => {
        setCode(value);

        if (autoTrigger && value.length === 6) {
            handleOtpSubmit(value);
            setAutoTrigger(false);
        }
    };

    const handleOtpSubmit = async (value) => {
        setLoadingSubmit(true);

        const otpCode = value || code;

        if (otpCode.length !== 6) {
            onAlert('Please enter a 6-digit code', 'warning', 'Attention');
            return;
        }

        const data = {
            email,
            password,
            code: otpCode
        };

        try {
            const response = await apiRequest('/login', 'POST', data);

            if (response.status === 201) {
                WiseMetering.startApp();
            } else {
                onAlert('Login failed', 'destructive', 'Failed');
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error?.capitalize() || 'An error occurred';

            onAlert(errorMessage, 'destructive', 'Failed');
        } finally {
            setTimeout(() => {
                setLoadingSubmit(false);
            }, 500);
        }
    };

    const handleResendOtp = async () => {
        setLoadingResend(true);

        const data = {
            email,
            password
        };

        try {
            const response = await apiRequest('/login', 'POST', data);

            if (response.status === 200) {
                onAlert('A new code has been sent', 'info', 'Info')
            }
        } catch(err) {
            const errorMessage = err.response?.data?.error?.capitalize() || 'An error occurred';

            onAlert(errorMessage, 'destructive', 'Failed')
        } finally {
            setTimeout(() => {
                setLoadingResend(false);
            }, 500);
        }
    };

    return (
        <LoginCard>
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mb-4">
                <FormLabel htmlFor="otp">Enter the code sent to your email</FormLabel>
                <InputOTP maxLength={6} pattern={REGEXP_ONLY_DIGITS_AND_CHARS} onChange={onOtpChange}>
                    <InputOTPGroup className="tw-flex tw-gap-4">
                        <InputOTPSlot className="tw-border-gray-300 tw-rounded-md !tw-border-solid !tw-border-2" index={0} />
                        <InputOTPSlot className="tw-border-gray-300 tw-rounded-md !tw-border-solid !tw-border-2" index={1} />
                        <InputOTPSlot className="tw-border-gray-300 tw-rounded-md !tw-border-solid !tw-border-2" index={2} />
                        <InputOTPSlot className="tw-border-gray-300 tw-rounded-md !tw-border-solid !tw-border-2" index={3} />
                        <InputOTPSlot className="tw-border-gray-300 tw-rounded-md !tw-border-solid !tw-border-2" index={4} />
                        <InputOTPSlot className="tw-border-gray-300 tw-rounded-md !tw-border-solid !tw-border-2" index={5} />
                    </InputOTPGroup>
                </InputOTP>
            </div>
            <Button loading={loadingSubmit} className="tw-w-full" variant="secondary" onClick={() => handleOtpSubmit()} disabled={!formFilled}>
                Verify
            </Button>
            <div className="tw-flex tw-justify-between tw-gap-2">
                <StandaloneLink onAction={backToLogin} icon="arrowLeftCircle" text="Back to Login" />
                <StandaloneLink onAction={handleResendOtp} text="Resend Code" loading={loadingResend}/>
            </div>
        </LoginCard>
    );
}

