WiseMetering.Views.EditPassword = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'user',
    template: 'templates/users/edit_password',
    title: () => i18next.t('modal.title.passwords.edit'),

    save: function() {
        this.$('label').removeClass('err');
        this.$('label').find('.err-help').remove();
        $(this.modal.errors.el).removeClass('active').empty();

        let json = this.formSerializer()
        //this.model.changePassword(json)
        return $.ajax({
            url: this.model.url() + '/change_password',
            dataType: 'json',
            data: JSON.stringify(json),
            type: 'PUT',
            contentType: 'application/json',
        }).done( () => {
            this.modal.unblock();
            this.modal.close();
        }).fail(xhr => {

            let errors = JSON.parse(xhr.responseText);

             if (errors.error) {
                 $(this.modal.errors.el).addClass('active').html(errors.error);
             }
            this.modal.unblock();
        });
    },
});
