module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'user_current_password\'>' +
((__t = ( i18next.t('common.current_password') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'user_current_password\' name=\'user[current_password]\' type=\'password\' autocomplete=\'current-password\'>\n        </dd>\n        <dt>\n            <label for=\'user_password\'>' +
((__t = ( i18next.t('common.new_password') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'user_new_password\' name=\'user[password]\' type=\'password\' minlength=\'16\' autocomplete=\'new-password\'>\n        </dd>\n        <dt>\n            <label for=\'user_password_confirmation\'>' +
((__t = ( i18next.t('glossary.password_confirmation') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'user_new_password_confirmation\' name=\'user[password_confirmation]\' type=\'password\' autocomplete=\'new-password\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}